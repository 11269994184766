let orderSendBtn = document.querySelectorAll('.order-send')

if(orderSendBtn.length) {
    for(let elem of orderSendBtn) {
        elem.addEventListener('click', (e) => {
            e.preventDefault()

            let wrapper = elem.closest('form')
            let popup = elem.closest('.popup')
            let fields = {
                name: {
                    required: true
                },
                phone: {
                    required: true
                },
                message: {
                    required: false
                }
            }
            let data = {}
            let errors = 0

            for(let key in fields) {
                let value = fields[key]
                let {required} = value
                let field = wrapper.querySelector('.' + key)

                if(field){
                    let val = field.getValue()
        
                    if(required){
                        errors += field.check()
                    }
        
                    data[key] = val
                }
            }
        
            if(!errors){
                elem.disable(true)
        
                requestAjax('send_request', data, () => {
                    add_notif({
                        title: 'Спасибо!',
                        text: 'Ваша заявка была отправлена',
                        icon: 'success',
                        color: 'green',
                        timeout: 6000,
                    })
        
                    elem.enable()
                    
                    for(let key in fields) {
                        let field = wrapper.querySelector('.' + key)
                
                        if(field){
                            field.clear()
                        }
                    }
                    
                    if(popup) {
                        closePopup(popup)
                    }
                })
            }else{
                add_notif({
                    title: 'Ошибка',
                    text: 'Заполните все поля',
                    icon: 'error',
                    color: 'red',
                    timeout: 3000,
                })
            }
        })
    }
}

if(window.city){
    let check = localStorage.getItem('first_city')

    if(!check){
        localStorage.setItem('first_city', window.city)
    }

    localStorage.setItem('city', window.city)
}