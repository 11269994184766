HTMLElement.prototype.phoneMask = function() {
    let mask = new IMask(this, {
        mask: '+{7} (000) 000-00-00',
        lazy: this.classList.contains('hide-mask'),
        placeholderChar: '_'
    })

    mask.type = 'phone'
    this.mask = mask
}

HTMLElement.prototype.timeMask = function() {
    let mask = new IMask(this, {
        mask: 'H:M',
        lazy: false,
        placeholderChar: '_',
        blocks: {
            H: {
                mask: IMask.MaskedRange,
                from: 0,
                to: 23,
                maxLength: 2,
            },
            M: {
                mask: IMask.MaskedRange,
                from: 0,
                to: 59,
                maxLength: 2,
            }
        }
    })

    mask.type = 'time'
    this.mask = mask
}

HTMLElement.prototype.priceMask = function() {
    let mask = new IMask(this, {
        mask: Number,
        min: 0,
        max: 10000000,
        thousandsSeparator: ' '
    })

    mask.type = 'price'
    this.mask = mask
}

HTMLElement.prototype.dateMask = function() {
    let mask = new IMask(this, {
        mask: Date,
        lazy: false,
        placeholderChar: '_'
    })

    mask.type = 'date'
    this.mask = mask
}

HTMLElement.prototype.slugMask = function() {
    let mask = new IMask(this, {
        mask: /^\w+$/,
    })

    mask.type = 'slug'
    this.mask = mask
}

let priceInputs = document.querySelectorAll('.price-mask')
if(priceInputs.length) {
    for(let input of priceInputs) {
        input.priceMask()
    }
}

let dateInputs = document.querySelectorAll('.date-input, .date-mask')
if(dateInputs.length) {
    for(let input of dateInputs) {
        input.dateMask()
    }
}

let slugInputs = document.querySelectorAll('.slug-mask')
if(slugInputs.length) {
    for(let input of slugInputs) {
        input.slugMask()
    }
}

let timeInputs = document.querySelectorAll('.time-mask')
if(timeInputs.length) {
    for(let input of timeInputs) {
        input.timeMask()
    }
}

let phoneInputs = document.querySelectorAll('.phone-mask')
if(phoneInputs.length) {
    for(let input of phoneInputs) {
        input.phoneMask()
    }
}