function postAjax(name, data){
    axios.post('/wp-admin/admin-ajax.php?action=' + name, data)
}

function requestAjax(name, data, func){
    axios.post('/wp-admin/admin-ajax.php?action=' + name, data)
    .then(function (response) {
        if(func) {
            func(response.data)
        }
    })
}

function unmaskedPhone(val){
    return val.replace(/[^0-9]/g, '')
}