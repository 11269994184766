let menu = document.querySelector('.mobile-menu')
let openMenu = document.querySelector('.open-menu')
let closeMenu = document.querySelector('.close-mobile-menu')

window.addEventListener('load', () => {
    menu.classList.add('loaded')
})

openMenu.addEventListener('click', () => {
    menu.classList.add('show')
})

closeMenu.addEventListener('click', () => {
    menu.classList.remove('show')
})