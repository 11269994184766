let advantagesSlider = document.querySelector('.advantages-slider')

if(advantagesSlider) {
    new Swiper(advantagesSlider, {
        slideClass: 'advantage',
        speed: 600,
        slidesPerView: 1,
        pagination: {
            el: '.advantages-dots',
        }
    })
}