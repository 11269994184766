let images = document.querySelectorAll('.images-grid .image')

if(images.length) {
    let options = []

    for(let image of images) {
        let img = image.querySelector('img')
        let src = img.getAttribute('src')
        
        options.push({
            src: src,
            type: 'image'
        })

        image.addEventListener('click', () => {
            let other = Array.prototype.slice.call( images )
            let index = other.indexOf(image)
        
            Fancybox.show(
                options,
                {
                    startIndex: index,
                    infinite: false,
                }
            )
        })
    }
}