function openPopup(popup){
    if(typeof(popup) == 'string'){
        popup = document.querySelector('.popup.' + popup)
    }

    if(popup) {
        popup.style.display = 'block'
    }
}

function closePopup(popup){
    if(typeof(popup) == 'string'){
        popup = document.querySelector('.popup.' + popup)
    }

    if(popup) {
        popup.style.display = 'none'
    }
}

let orderBtn = document.querySelectorAll('.order-btn')

if(orderBtn.length) {
    for(let btn of orderBtn) {
        btn.addEventListener('click', () => {
            openPopup('order-popup')
        })
    }
}

let closePopupBtn = document.querySelectorAll('.close-popup')

if(closePopupBtn.length) {
    for(let elem of closePopupBtn) {
        elem.addEventListener('click', () => {
            let popup = elem.closest('.popup')
            let btn = popup.querySelector('.btn')
    
            closePopup(popup)
            btn.removeAttribute('disabled')
        })
    }
}

/////

let orderBtns = document.querySelectorAll('.order-btn')

if(orderBtns.length) {
    for(let btn of orderBtns) {
        btn.addEventListener('click', () => {
            let popup = document.querySelector('#order_popup')

            openPopup(popup)
        })
    }
}