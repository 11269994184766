let scrollDownBtn = document.querySelector('.scroll-down')

if(scrollDownBtn) {
    scrollDownBtn.addEventListener('click', () => {
        let section = scrollDownBtn.closest('section')
        let next = section.nextElementSibling

        next.scrollIntoView({
            behavior: 'smooth'
        })
    })
}

let anchor = document.querySelectorAll('[href^="#"]')

if(anchor.length) {
    for(let elem of anchor) {
        elem.addEventListener('click', (e) => {
            let id = elem.getAttribute('href')
            let scrollTo = document.querySelector(id)

            if(scrollTo) {
                scrollTo.scrollIntoView({
                    behavior: 'smooth'
                })
            }

            e.preventDefault()
        })
    }
}